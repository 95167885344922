import React, { useState, useEffect } from 'react';
import { caffenioUbicacionAxios } from '../Utils/axios';
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from 'react-leaflet';
import { MarkerIcon } from './react-leaflet-icon.js';
import './css/style.css';
import Estados from './components/Estados';
import Ciudades from './components/Ciudades';
import Cafeterias from './components/Cafeterias';
import PopupCafeteria from './components/Popup';

const ACCESS_TOKEN =
  'pk.eyJ1IjoibWFwYWNhZiIsImEiOiJjbTd4eTR0MjgwMXBnMmlvajl3dnVnOWN2In0.IlAlbyJ9d-QN00A4RyKlrg';

const URL = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${ACCESS_TOKEN}`;
const ATTRIBUTION =
  'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>';

const coordenadas_fijas = [29.0609, -111];

function App() {
  const [mapa, setMapa] = useState(null);
  const [coordenadas, guardarCoordenadas] = useState([]);
  const [zoom, guardarZoom] = useState(16);
  const [cafeterias, setCafeterias] = useState([]);
  const [estados, setEstados] = useState([]);
  const [selEstadoActivo, setEstadoActivo] = useState(false);
  const [selEstados, setSelEstados] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [ciudadesEstado, setCiudadesEstado] = useState([]);
  const [cafeteriasCiudad, setCafeteriasCiudad] = useState([]);
  const [show, setShow] = useState(false);
  const [cafeteria, setCafeteria] = useState({});

  useEffect(() => {
    const posicionActual = () => {
      if ('geolocation' in navigator) {
        console.log('Available');
        guardarCoordenadas(coordenadas_fijas);

      } else {
        console.log('Not Available');
        guardarCoordenadas(coordenadas_fijas);
      }
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const latitude = position.coords.latitude;
          const lng = position.coords.longitude;
          let coordenadas = [latitude, lng];
          guardarCoordenadas(coordenadas);
        },
        function (error) {
          console.error('Error Code = ' + error.code + ' - ' + error.message);
        }
      );
    };
    posicionActual();

    const getInfo = async () => {
      const urlCafeterias = caffenioUbicacionAxios('api/v1/obtener_cafeterias');
      const urlEstados = caffenioUbicacionAxios('wp/v2/estados?per_page=100');
      const urlCiudades = caffenioUbicacionAxios('wp/v2/ciudades?per_page=100');
      const [cafeterias, estados, ciudades] = await Promise.all([
        urlCafeterias,
        urlEstados,
        urlCiudades,
      ]);
      setCafeterias(cafeterias.data);
      setEstados(estados.data);
      setCiudades(ciudades.data);
    };
    getInfo();
  }, []);

  const changeView = (center, zoom) => {
    mapa.setView(center, zoom);
  };

  const onChangeEstados = async (option) => {
    setSelEstados({
      id: option.id,
      name: option.name,
    });
    obtenerCiudadesPorEstado(option.id);
  };

  const obtenerCiudadesPorEstado = (id) => {
    const ciudadesFiltro = cafeterias.filter(
      (cafeteria) => cafeteria.estados[0].term_id == id
    );

    const ciudadesXEstado = [];
    ciudadesFiltro.forEach((item) => {
      if (ciudadesXEstado.length > 0) {
        let b;
        b = ciudadesXEstado.every(
          (ciudad) => item.ciudades[0].term_id != ciudad.ciudades[0].term_id
        );
        if (b) {
          ciudadesXEstado.push(item);
        }
      } else {
        ciudadesXEstado.push(item);
      }
    });

    let ciudadesEstado = [];
    if (ciudadesXEstado.length > 0) {
      ciudadesXEstado.forEach((item) => {
        const ciudadEstado = ciudades.filter(
          (ce) => item.ciudades[0].term_id == ce.id
        );
        ciudadesEstado.push(ciudadEstado[0]);
      });
    }
    setCiudadesEstado(ciudadesEstado);
  };

  const onClickCiudad = (id) => {
    const cafeteriasCiudad = cafeterias.filter(
      (cafeteria) => cafeteria.ciudades[0].term_id == id
    );

    setCafeteriasCiudad(cafeteriasCiudad);
    setEstadoActivo(true);

  };

  const showModal = (cafeteria) => {
    setCafeteria(cafeteria);
    setShow(true);
  };

  console.log('selEstadoActivo ', selEstadoActivo);
  return (
    <>
      {show && <PopupCafeteria cafeteria={cafeteria} setShow={setShow} />}
      <Estados
        estados={estados}
        selEstados={selEstados}
        setSelEstados={setSelEstados}
        onChangeEstados={onChangeEstados}
      />
      {ciudadesEstado.length > 0 && (
        <Ciudades
          ciudades={ciudadesEstado}
          onChangeEstados={onChangeEstados}
          onClickCiudad={onClickCiudad}
        />
      )}
      {
        coordenadas.length > 0 && (
          <div className="row ubicacion-container">
            <div className={`contenedor-info-cafeterias row ${selEstadoActivo ? '' :  'ds-none'}`}>
              {cafeteriasCiudad.length > 0 ? (
                <Cafeterias
                  cafeterias={cafeteriasCiudad}
                  showModal={showModal}
                  changeView={changeView}
                />
              ) : (
                <Cafeterias
                  cafeterias={cafeterias}
                  showModal={showModal}
                  changeView={changeView}
                />
              )}
            </div>
            <div className={`contendor-info-mapa ${selEstadoActivo ? 'show-cities' : ''}`}>
              <MapContainer
                center={coordenadas}
                zoom={zoom}
                whenCreated={setMapa}
              >
                <TileLayer url={URL} attribution={ATTRIBUTION} />
                {cafeterias &&
                  cafeterias.map((cafeteria, index) => (
                    <Marker
                      key={index}
                      position={[cafeteria.latitude, cafeteria.longitud]}
                      icon={MarkerIcon}
                    >
                      <Popup>
                        <b>{cafeteria.title}</b>
                        <br />
                        <div className="text-center">
                          Horario
                          <br />
                          Lunes a Viernes
                          <br />
                          {cafeteria.mondaytofriday}
                          <br />
                          Sabado
                          <br />
                          {cafeteria.saturdat}
                          <br />
                          Domingo
                          <br />
                          {cafeteria.sunday}
                        </div>
                      </Popup>
                    </Marker>
                  ))}
              </MapContainer>
            </div>
          </div>
        )
        //: (
        //   <div>
        //     <h1>ERROR</h1>
        //     {coordenadas.length}
        //   </div>
        // )
      }
    </>
  );
}

export default App;
